import { flowRight, get } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TimeAgo from '../time-ago';
import Link from '../link/internal-link';
import UserAvatarImage from '../user-avatar-image';
import MemberCardAsync from '../../containers/member-card-async';
import { connect } from '../../../common/components/runtime-context';
import {
  RECENT_ACTIVITY_REPLY,
  RECENT_ACTIVITY_COMMENT,
} from '../../constants/recent-activity-types';
import styles from './post-list-item-recent-activity.scss';
import AvatarImage from '../avatar-image';
import { POST_LIST_ITEM_RECENT_ACTIVITY } from '../../constants/bi-locations';
import { withErrorBoundary } from '@wix/communities-forum-client-commons/dist/src/components/with-error-boundary';
import withTranslate from '@wix/communities-forum-client-commons/dist/src/hoc/with-translate';

class PostListItemRecentActivity extends Component {
  render() {
    const { post, category, resolveCommentDeepLinkUrl, t } = this.props;

    let date;
    let user;
    let url;
    let dynamicLocationResolver;

    if (post.recentActivity && post.recentActivity.activityDate) {
      date = post.recentActivity.activityDate;
      user = post.recentActivity.initiator;
      url = `/main/comment/${post.recentActivity.entityId}`;
      dynamicLocationResolver = () =>
        [RECENT_ACTIVITY_COMMENT, RECENT_ACTIVITY_REPLY].includes(post.recentActivity.type) &&
        resolveCommentDeepLinkUrl(post.recentActivity.entityId);
    } else {
      date = post.lastActivityDate || post.createdDate;
      user = post.owner;
      url = `/${category.slug}/${post.slug}`;
    }

    return (
      <Link
        to={url}
        dynamicLocationResolver={dynamicLocationResolver}
        className={classNames('forum-text-color', 'forum-link-hover-color', styles.link)}
        biLocation={POST_LIST_ITEM_RECENT_ACTIVITY}
        data-hook="post-list-item__recent-activity"
        aria-label={t('post-list-item.recent-activity-nav-a11y')}
      >
        <MemberCardAsync viewedMemberId={user && user.siteMemberId}>
          <UserAvatarImage user={user} type={AvatarImage.MEDIUM} className={styles.avatar} />
        </MemberCardAsync>

        <TimeAgo className={styles.timeAgo} time={date} />
      </Link>
    );
  }
}

PostListItemRecentActivity.propTypes = {
  post: PropTypes.object,
  category: PropTypes.object,
  resolveCommentDeepLinkUrl: PropTypes.func,
  t: PropTypes.func,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => ({
  resolveCommentDeepLinkUrl: actions.resolveCommentDeepLinkUrlPromisified,
});

export default flowRight(
  withErrorBoundary,
  connect(mapRuntimeToProps),
  withTranslate,
)(PostListItemRecentActivity);
