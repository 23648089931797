import PropTypes from 'prop-types';
import React from 'react';

export const CommentIconSmall = ({ title, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" {...props}>
    <title>{title}</title>
    <path
      fill-rule="evenodd"
      d="M12 4c1.105 0 2 .895 2 2v4c0 1.105-.895 2-2 2H9.667l-3.899 2.924c-.268.211-.762.067-.768-.384V12c-1.105 0-2-.895-2-2V6c0-1.105.895-2 2-2h7zM5 5c-.552 0-1 .448-1 1v4c0 .552.448 1 1 1h1v2.5L9.333 11H12c.552 0 1-.448 1-1V6c0-.552-.448-1-1-1H5z"
    />
  </svg>
);

CommentIconSmall.propTypes = {
  title: PropTypes.string,
};
