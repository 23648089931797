import PropTypes from 'prop-types';
import React from 'react';
import PostListItem from '../post-list-item';
import CreatePostCta from '../create-post-cta';
import ItemContainer from '../post-list-mobile-common/item-container';
import NoPostsFiltered from '../no-posts-filtered';
import { HorizontalSeparator } from '../separator';

const PostListMobile = ({
  category,
  posts,
  query,
  onLikeClick,
  showCreatePostAction,
  hasActiveFilter,
  uniquePostTypesInAllCategories,
}) => {
  const postTypes =
    uniquePostTypesInAllCategories.length === 1
      ? uniquePostTypesInAllCategories
      : category.postTypes;
  return (
    <div>
      {showCreatePostAction && (
        <React.Fragment>
          <ItemContainer>
            <CreatePostCta categorySlug={category.slug} postTypes={postTypes} />
          </ItemContainer>
          <HorizontalSeparator isLarge />
        </React.Fragment>
      )}
      {!posts.length && hasActiveFilter && <NoPostsFiltered />}
      {posts.map((post, idx) => (
        <React.Fragment>
          <ItemContainer key={post._id}>
            <PostListItem
              post={post}
              query={query}
              type={PostListItem.MOBILE}
              onLikeClick={onLikeClick}
              showRecentActivity
            />
          </ItemContainer>
          {idx < posts.length - 1 ? <HorizontalSeparator isLarge /> : null}
        </React.Fragment>
      ))}
    </div>
  );
};

PostListMobile.propTypes = {
  onLikeClick: PropTypes.func.isRequired,
  category: PropTypes.object,
  posts: PropTypes.array,
  location: PropTypes.object,
  query: PropTypes.string,
  showCreatePostAction: PropTypes.bool,
  hasActiveFilter: PropTypes.bool,
  uniquePostTypesInAllCategories: PropTypes.array,
};

export default PostListMobile;
