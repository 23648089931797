import { flowRight, get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
import classNames from 'classnames';
import { GatewayProvider, GatewayDest } from 'react-gateway';

import MessageRoot from '../messages/message-root';
import ModalRoot from '../modals/modal-root';
import DemoMessageRoot from '../../containers/demo-message-root';
import withMainPageEnabled from '../../hoc/with-main-page-enabled';
import ErrorState from '../error-state';
import { getLocation } from '../../../common/store/location/location-selectors';
import styles from './app.scss';
import { isPreview } from '../../../common/store/basic-params/basic-params-selectors';

export class AppMobile extends Component {
  scrollable = null;
  modalRootRef = null;

  setModalRootRef = ref => (this.modalRootRef = ref);

  setScrollable = node => {
    this.scrollable = node;
  };

  renderOutsideScroll() {
    const { headroomHeader, stickyHeader, menu } = this.props;
    if (headroomHeader) {
      return React.cloneElement(headroomHeader, { menu });
    }
    if (stickyHeader) {
      return React.cloneElement(stickyHeader, { menu });
    }
    return null;
  }

  renderInsideScroll() {
    const { simpleHeader, menu } = this.props;
    let header = null;
    if (simpleHeader) {
      header = React.cloneElement(simpleHeader, { menu });
    }
    return header;
  }

  /// start fast active - allow setting css when user taps on an element => need to add '.active' css rule to elements that you want to change
  // clearActive() {
  //   if (this.activeElement && this.activeElement.classList) {
  //     this.activeElement.classList.remove('active');
  //     this.activeElement = null;
  //   }
  // }

  // setActive(e) {
  //   this.clearActive();
  //   if (this.activeElement && this.activeElement.classList) {
  //     this.activeElement = e.target;
  //     this.activeElement.classList.add('active');
  //   }
  // }

  // componentDidMount() {
  //   if (
  //     'ontouchstart' in window ||
  //     (window.DocumentTouch && window.document instanceof window.DocumentTouch)
  //   ) {
  //     window.document.body.addEventListener('touchstart', this.setActive.bind(this), false);
  //     window.document.body.addEventListener('touchmove', this.clearActive.bind(this), false);
  //   }
  // }
  /// end fast active - allow setting css when user taps on an element => need to add '.active' css rule to elements that you want to change

  renderScroll() {
    const { main, overlay, children } = this.props;
    const props = {
      ref: this.setScrollable,
      className: classNames(styles.scrollable, 'app-mobile__scrollable'),
    };

    return (
      <div {...props}>
        {overlay}
        {this.renderInsideScroll()}
        {React.cloneElement(main, { children })}
      </div>
    );
  }

  render() {
    const { isDebug, isPreview } = this.props;
    return (
      <GatewayProvider>
        <div
          data-hook="forum-content-wrapper"
          id="content-wrapper"
          className={classNames(styles.app, 'app-mobile', 'forum-mobile-background-color')}
        >
          <div>
            <ModalRoot setRef={this.setModalRootRef} />
            <MessageRoot />
            <DemoMessageRoot />
            {this.renderOutsideScroll()}
            {this.renderScroll()}
            <GatewayDest name="floating-action-button" />
            <GatewayDest name="tooltip" />
            <GatewayDest name="rce-modal" />
            {isDebug && <ErrorState />}
            {isPreview && (
              <div
                style={{
                  height: this.modalRootRef ? 600 : 0,
                }}
              />
            )}
          </div>
        </div>
      </GatewayProvider>
    );
  }
}

AppMobile.propTypes = {
  children: PropTypes.node,
  simpleHeader: PropTypes.node,
  simpleHeaderMainPageDisabled: PropTypes.node,
  stickyHeader: PropTypes.node,
  stickyHeaderMainPageDisabled: PropTypes.node,
  headroomHeader: PropTypes.node,
  headroomHeaderMainPageDisabled: PropTypes.node,
  main: PropTypes.node,
  menu: PropTypes.node,
  categories: PropTypes.array,
  overlay: PropTypes.node,
  isMainPageEnabled: PropTypes.bool,
  isDebug: PropTypes.bool,
  isPreview: PropTypes.bool,
};

const mapRuntimeToProps = (
  state,
  {
    isMainPageEnabled,
    simpleHeader,
    simpleHeaderMainPageDisabled,
    stickyHeader,
    stickyHeaderMainPageDisabled,
    headroomHeader,
    headroomHeaderMainPageDisabled,
  },
) => {
  return {
    categories: state.categories,
    simpleHeader: isMainPageEnabled ? simpleHeader : simpleHeaderMainPageDisabled,
    stickyHeader: isMainPageEnabled ? stickyHeader : stickyHeaderMainPageDisabled,
    headroomHeader: isMainPageEnabled ? headroomHeader : headroomHeaderMainPageDisabled,
    isDebug: get(getLocation(state), 'query.debug'),
    isPreview: isPreview(state),
  };
};

export default flowRight(withMainPageEnabled, connect(mapRuntimeToProps))(AppMobile);
