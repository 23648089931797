import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
import { MODAL_TYPE_CATEGORIES_MANAGER } from '../../components/modals/categories-manager/categories-manager-modal-type';
import { MODAL_TYPE_DELETE_CATEGORY } from '../../components/modals/delete-category-modal/delete-category-modal-type';
import { MODAL_TYPE_UNFOLLOW_CATEGORY } from '../../components/modals/unfollow-category-modal/unfollow-category-modal-type';
import { DeleteIcon } from '../../components/icons/delete-icon';
import { EditIcon } from '../../components/icons/edit-icon';
import { GearIcon } from '../../components/icons/gear-icon';
import ActionButton from '../../components/action-button';
import { getCategoryCount } from '../../../common/selectors/categories-selectors';
import withPermissions from '../../hoc/with-permissions';
import withTranslate from '@wix/communities-forum-client-commons/dist/src/hoc/with-translate';
import withAuth from '../../hoc/with-auth';
import ensureAuth from '../../hoc/ensure-auth';
import { isPrivate } from '@wix/communities-forum-client-commons/dist/src/services/category-privacy-utils';
import { LockEmptyIcon } from '../../components/icons/lock-empty-icon';
import { MANAGE_MEMBERS } from '../../constants/categories-manager-section-types';
import { NotificationIcon } from '../../components/icons/notification-icon';
import { NotificationFollowingIcon } from '../../components/icons/notification-following-icon';
import ActionsDivider from '../../components/actions-divider';
import { CATEGORIES_MANAGER_EDIT_CATEGORY } from '../../actions/categories-manager-actions-constants';
import { THREE_DOT_ACTIONS } from '../../constants/bi-locations';

const ActionButtonAuth = ensureAuth(ActionButton);

export function doesUserHavePermissionsToSeeCategoryActions(can, category) {
  // the logic is that everyone can subscribe expect those who cannot access category. Edit check is just in case for owners :)
  return can('subscribe', 'category', category) || can('edit', 'category', category);
}

export class CategoryActions extends Component {
  componentDidMount() {
    this.props.actionsOpened({ type: null });
  }

  componentWillUnmount() {
    this.props.actionsClosed({ type: null });
  }

  handleSubscribe = () => {
    const {
      category: { _id },
      subscribe,
      buttonClicked,
    } = this.props;
    buttonClicked({ action: 'follow_category', flag: 1 });

    return subscribe(_id);
  };

  handleUnsubscribe = () => {
    const {
      category: { _id },
      buttonClicked,
    } = this.props;
    buttonClicked({ action: 'unfollow_category', flag: 0 });

    this.props.openModal(MODAL_TYPE_UNFOLLOW_CATEGORY, { categoryId: _id });
  };

  handleEdit = () => {
    this.props.setBiLocation(THREE_DOT_ACTIONS, CATEGORIES_MANAGER_EDIT_CATEGORY);
    this.props.buttonClicked({ action: 'edit_category' });
    this.props.openModal(MODAL_TYPE_CATEGORIES_MANAGER, { categoryId: this.props.category._id });
  };

  handleDelete = () => {
    this.props.buttonClicked({ action: 'delete_category' });
    this.props.openModal(MODAL_TYPE_DELETE_CATEGORY, { categoryId: this.props.category._id });
  };

  handleManageCategories = () => {
    this.props.buttonClicked({ action: 'manage_category' });
    this.props.openModal(MODAL_TYPE_CATEGORIES_MANAGER, { categoryId: null });
  };

  render() {
    const {
      canRender,
      category,
      t,
      forPublicUser,
      isOneCategoryRemaining,
      isOwner,
      hideFollowButton,
    } = this.props;
    const followButton =
      !hideFollowButton &&
      canRender('subscribe', 'category', category, can => {
        const action = category.isSubscribed ? this.handleUnsubscribe : this.handleSubscribe;
        const ActionComponent = can ? ActionButton : ActionButtonAuth;
        return (
          <ActionComponent
            dataHook="category-actions__subscriptions"
            onClick={can ? action : forPublicUser(action)}
          >
            {category.isSubscribed ? <NotificationFollowingIcon /> : <NotificationIcon />}
            {t(
              category.isSubscribed
                ? 'category-actions.unsubscribe-category'
                : 'category-actions.subscribe-category',
            )}
          </ActionComponent>
        );
      });

    const editButton = isOwner ? (
      <ActionButton dataHook="category-actions__edit" onClick={this.handleEdit}>
        <EditIcon />
        {t('category-actions.edit-category')}
      </ActionButton>
    ) : null;

    const deleteButton =
      isOwner && !isOneCategoryRemaining ? (
        <ActionButton dataHook="category-actions__delete" onClick={this.handleDelete}>
          <DeleteIcon />
          {t('category-actions.delete-category')}
        </ActionButton>
      ) : null;

    const manageCategoriesButton = canRender('edit', 'category', can => {
      return can ? (
        <ActionButton dataHook="category-actions__manage" onClick={this.handleManageCategories}>
          <GearIcon />
          {t('breadcrumbs-actions.manage-categories')}
        </ActionButton>
      ) : null;
    });

    const manageMembersButton = canRender('edit', 'category', can => {
      return isPrivate(category) && can ? (
        <ActionButton
          dataHook="category-actions__manage-members"
          onClick={this.handleManageMembers}
        >
          <LockEmptyIcon className="forum-icon-fill" />
          {t('category-actions.manage-category-members')}
        </ActionButton>
      ) : null;
    });

    const shouldRenderDivider =
      (followButton || editButton || deleteButton) && manageCategoriesButton;

    return (
      <div>
        {followButton}
        {editButton}
        {manageMembersButton}
        {deleteButton}
        {shouldRenderDivider && <ActionsDivider />}
        {manageCategoriesButton}
      </div>
    );
  }

  handleManageMembers = () => {
    this.props.setBiLocation(THREE_DOT_ACTIONS, CATEGORIES_MANAGER_EDIT_CATEGORY);
    this.props.buttonClicked({ action: 'manage_category_members' });
    this.props.openModal(MODAL_TYPE_CATEGORIES_MANAGER, {
      categoryId: this.props.category._id,
      openPanels: [MANAGE_MEMBERS],
    });
  };
}

CategoryActions.propTypes = {
  category: PropTypes.object.isRequired,
  openModal: PropTypes.func,
  subscribe: PropTypes.func,
  canRender: PropTypes.func,
  actionsOpened: PropTypes.func,
  actionsClosed: PropTypes.func,
  buttonClicked: PropTypes.func,
  t: PropTypes.func,
  forPublicUser: PropTypes.func,
  isOwner: PropTypes.bool,
  isOneCategoryRemaining: PropTypes.bool,
  hideFollowButton: PropTypes.bool,
  setBiLocation: PropTypes.func,
};

const mapRuntimeToProps = (state, { category }, actions) => {
  return {
    isOneCategoryRemaining: getCategoryCount(state) <= 1,
    subscribe: actions.subscribeToCategory,
    openModal: actions.openModal,
    actionsOpened: actions.actionsOpened,
    actionsClosed: actions.actionsClosed,
    setBiLocation: actions.setBiLocation,
    buttonClicked: data =>
      actions.buttonClicked({
        name: 'action_click',
        type: 'post',
        ...data,
        categoryId: category._id,
      }),
  };
};

// prettier-ignore
export default flowRight(
  connect(mapRuntimeToProps),
  withPermissions,
  withTranslate,
  withAuth,
)(CategoryActions);
