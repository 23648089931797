import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import styles from './page.scss';
import { HorizontalSeparator } from '../separator';
import withDeviceType from '../../hoc/with-device-type';

const Page = ({ className, noSpacing, noSpacingTop, isMobile, children }) => (
  <React.Fragment>
    {isMobile && !noSpacing && <HorizontalSeparator isLarge />}
    <div
      className={classNames(
        styles.container,
        { [styles.noSpacing]: noSpacing, [styles.noSpacingTop]: noSpacingTop },
        className,
      )}
    >
      {children}
    </div>
    {isMobile && !noSpacing && <HorizontalSeparator isLarge />}
  </React.Fragment>
);

Page.propTypes = {
  className: PropTypes.string,
  noSpacing: PropTypes.bool,
  noSpacingTop: PropTypes.bool,
  children: PropTypes.node,
  isMobile: PropTypes.bool,
};

export default withDeviceType(Page);
