import React from 'react';
import PropTypes from 'prop-types';
import { NotificationIcon } from '../icons/notification-icon';
import { Interpolate } from 'react-i18next';
import IconTooltip from '../icon-tooltip/icon-tooltip';
import classNames from 'classnames';
import styles from './follow-notifications.scss';
import { NotificationFollowingIcon } from '../icons/notification-following-icon';
import withExperiment from '../../hoc/with-experiment';
import { EXPERIMENT_VISUAL_UPDATE } from '@wix/communities-forum-client-commons/dist/src/constants/experiments';

export const FollowNotification = ({ className, isFollowing, isVisualUpdateEnabled }) => {
  if (!isVisualUpdateEnabled) {
    return (
      <Interpolate
        i18nKey={isFollowing ? 'category-actions.unfollow' : 'category-actions.follow'}
        useDangerouslySetInnerHTML
      />
    );
  }
  const tooltipText = (
    <Interpolate
      i18nKey={
        isFollowing
          ? 'category-actions.follow-notification-tooltip-following'
          : 'category-actions.follow-notification-tooltip'
      }
      useDangerouslySetInnerHTML
    />
  );
  return (
    <IconTooltip offset={10} text={tooltipText} className={styles.iconContainer}>
      {isFollowing ? (
        <NotificationFollowingIcon
          className={classNames(className)}
          data-hook="notifications__bell-icon-following"
        />
      ) : (
        <NotificationIcon className={classNames(className)} data-hook="notifications__bell-icon" />
      )}
    </IconTooltip>
  );
};

FollowNotification.propTypes = {
  className: PropTypes.string,
  isFollowing: PropTypes.bool,
};

export default withExperiment({
  isVisualUpdateEnabled: EXPERIMENT_VISUAL_UPDATE,
})(FollowNotification);
