import { convertFromRaw } from '../../common/services/editor-state-conversion';

export const createInitialDraftJsContentWithText = text =>
  convertFromRaw({
    blocks: [
      {
        key: 'foo',
        text,
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
    ],
    entityMap: {},
  });
