import { flowRight } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { connect } from '../../../common/components/runtime-context';
import { getIsMobile } from '../../../common/store/basic-params/basic-params-selectors';
import AvatarGroup from '../avatar-group';
import withTranslate from '@wix/communities-forum-client-commons/dist/src/hoc/with-translate';
import MoreButton from '../more-button';
import styles from './who-liked-popover.scss';
import WhoLikedList from './who-liked-list';
import { MODAL_TYPE_WHO_LIKED_LIST } from '../modals/who-liked-list-modal/who-liked-list-modal-type';

const LikeCount = props => {
  return (
    <span
      className={classNames(styles.likeCount, 'post-main-actions__like-count', {
        '.forum-link-hover-color:hover': props.totalItemCount > 0,
        [styles.noHover]: props.totalItemCount === 0,
      })}
      data-hook="who-liked-popover-btn"
      onClick={props.onClick}
    >
      {props.totalItemCount}
    </span>
  );
};

const LikeCountCTA = true ? LikeCount : AvatarGroup;

class WhoLikedPopover extends Component {
  reportBi = () => {
    this.props.buttonClicked({ name: 'who_liked', type: this.props.type });
  };
  render() {
    const { isMobile, totalItemCount, initialItems, postId, commentId, openModal, t } = this.props;

    if (totalItemCount === 0) {
      return (
        <LikeCountCTA totalItemCount={totalItemCount} items={initialItems} isMobile={isMobile} />
      );
    }
    return isMobile ? (
      <LikeCountCTA
        isMobile={isMobile}
        totalItemCount={totalItemCount}
        items={initialItems}
        onClick={() => {
          openModal(MODAL_TYPE_WHO_LIKED_LIST, { postId, commentId });
          this.reportBi();
        }}
      />
    ) : (
      <MoreButton
        ariaLabel={t('who-liked.aria-label')}
        icon={
          <LikeCountCTA totalItemCount={totalItemCount} items={initialItems} isMobile={isMobile} />
        }
        actionsContainerClassName={styles.popOverContainer}
        parentRef={this.getParentRef}
        position="right"
        onShow={this.reportBi}
        noCloseOnContainerClick
      >
        {<WhoLikedList postId={postId} commentId={commentId} />}
      </MoreButton>
    );
  }
}

WhoLikedPopover.propTypes = {
  isMobile: PropTypes.bool,
  totalItemCount: PropTypes.number,
  initialItems: PropTypes.array,
  postId: PropTypes.array,
  commentId: PropTypes.array,
  buttonClicked: PropTypes.func,
  type: PropTypes.oneOf(['post', 'comment', 'reply']),
  t: PropTypes.func,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => ({
  isMobile: getIsMobile(state),
  openModal: actions.openModal,
  buttonClicked: actions.buttonClicked,
});

export default flowRight(connect(mapRuntimeToProps), withTranslate)(WhoLikedPopover);
