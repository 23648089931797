import PropTypes from 'prop-types';
import React from 'react';

export const ViewCountIconSmall = ({ title, ...props }) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>{title}</title>
    <path
      d="M19.706461,12.8003829 C18.6462505,14.0573545 15.770461,16.9825189 11.9691978,17 L11.9363557,17 C9.2736189,17 6.70182943,15.5782069 4.29088206,12.7720802 C3.90303931,12.3180321 3.90303931,11.6536653 4.29088206,11.1996171 C5.35109258,9.94264547 8.22688206,7.01748106 12.0281452,7 L12.0618294,7 C14.7245663,7 17.2963557,8.42179306 19.7073031,11.2279198 C20.0945563,11.6822959 20.0942007,12.3464124 19.706461,12.8003829 Z M19.0462505,11.7690002 C16.8070926,9.16265712 14.458461,7.84158828 12.0626715,7.84158828 L12.03404,7.84158828 C8.59909258,7.85740448 5.94225048,10.5694664 4.96035574,11.7340381 C4.83485738,11.8764961 4.83200749,12.0877821 4.9536189,12.233497 C7.19277679,14.8398402 9.54225048,16.1600766 11.9371978,16.1600766 L11.9666715,16.1600766 C15.4016189,16.1450928 18.0576189,13.4330309 19.0403557,12.2684592 C19.1659565,12.1259276 19.1684531,11.9143897 19.0462505,11.7690002 L19.0462505,11.7690002 Z M11.9995136,15.2444548 C10.1915908,15.220571 8.74320294,13.7566886 8.75922149,11.9694456 C8.77524004,10.1822026 10.2496392,8.74391784 12.0577054,8.75172387 C13.8657716,8.75959366 15.3273529,10.2106058 15.3275136,11.9979189 C15.3136097,13.8020989 13.8246841,15.2545543 11.9995136,15.2444548 L11.9995136,15.2444548 Z M11.9995136,9.59797613 C10.661829,9.61634792 9.59066631,10.6999183 9.6029851,12.0223071 C9.6153039,13.344696 10.7064714,14.4085831 12.0442706,14.4025834 C13.3820698,14.3965327 14.4633851,13.3228611 14.4635136,12.0004162 C14.4528553,10.6649807 13.350487,9.59017281 11.9995136,9.59797613 L11.9995136,9.59797613 Z"
      id="Views"
      fill-rule="nonzero"
    ></path>
  </svg>
);

ViewCountIconSmall.propTypes = {
  title: PropTypes.string,
};
