import { flowRight, omit } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
// import { connect } from '../../../common/components/runtime-context';
import classNames from 'classnames';

import withTranslate from '@wix/communities-forum-client-commons/dist/src/hoc/with-translate';
import withAuth from '../../hoc/with-auth';
import IconButton from '../icon-button';
import { NotificationIcon } from '../icons/notification-icon';
// import { getCount } from '../../selectors/notification-selectors';
// import styles from './notification-button.scss';

const NotificationButton = ({ bubbleClassName, className, count, t, ...otherProps }) => {
  // const bubbleCssClass = classNames(styles.bubble, 'notification-button__bubble', bubbleClassName);
  return (
    <IconButton
      className={classNames('notification-button', className)}
      icon={NotificationIcon}
      label={t('breadcrumbs.notifications')}
      aria-label={t('breadcrumbs.notifications')}
      {...omit(otherProps, ['dispatch', 'i18n'])}
    >
      {/*{count ? (*/}
      {/*<span className={bubbleCssClass} data-hook="notification-bubble">*/}
      {/*{count}*/}
      {/*</span>*/}
      {/*) : null}*/}
    </IconButton>
  );
};

NotificationButton.propTypes = {
  // count: PropTypes.number.isRequired,
  bubbleClassName: PropTypes.string,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  t: PropTypes.func.isRequired,
};

// const mapRuntimeToProps = state => ({
//   count: getCount(state),
// });

export default flowRight(
  // connect(mapRuntimeToProps), // @todo: s.dubinskas restore notification count?
  withTranslate,
  withAuth,
)(NotificationButton);
