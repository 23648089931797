import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
import classNames from 'classnames';
import { isPrivate } from '@wix/communities-forum-client-commons/dist/src/services/category-privacy-utils';
import { compactNumber } from '../../services/format-number';
import { COVER_TYPE_IMAGE } from '../../constants/cover-types';
import { ImgBackground } from '@wix/communities-image-lib';
import { LockEmptyIcon } from '../icons/lock-empty-icon';
import { CurrencySignIcon } from '../icons/currency-sign-icon';
import MoreButton from '../more-button';
import CategoryActions from '../../containers/category-actions';
import Link from '../link/internal-link';
import FollowButton from '../follow-button/follow-button';
import {
  isCacheableRendering,
  getLanguage,
} from '../../../common/store/basic-params/basic-params-selectors';
import { hasCategoryPaidPlans } from '../../selectors/paid-plans-selectors';
import withFontClassName from '../../hoc/with-font-class-name';
import withPermissions from '../../hoc/with-permissions';
import withTranslate from '@wix/communities-forum-client-commons/dist/src/hoc/with-translate';
import styles from './category-list-item-desktop.scss';
import FollowNotification from '../follow-notification';
import { ViewCountIconSmall } from '../icons/view-count-icon-small';
import { CommentIconSmall } from '../icons/comment-icon-small';
import NewContentIndicator from '../new-content-indicator';
import { SORT_BY_NEWEST } from '@wix/communities-forum-client-commons/dist/src/constants/sorting';

export class CategoryListItemDesktop extends Component {
  hasImage = () => this.props.category.coverType === COVER_TYPE_IMAGE;

  renderImageIfNeeded = () => {
    const image = this.hasImage() && (
      <ImgBackground
        className={styles.coverImage}
        src={this.props.category.cover}
        width={100}
        height={60}
        aria-labelledby={`cl-${this.props.category._id}`}
      />
    );
    return image && this.linkToPosts(image);
  };

  renderContentBlock = () => {
    const { titleFontClassName, category, hasCategoryPP } = this.props;
    let categoryBadge = null;
    if (isPrivate(category)) {
      const Icon = hasCategoryPP ? CurrencySignIcon : LockEmptyIcon;
      categoryBadge = (
        <span className={styles.categoryBadgeWrapper}>
          <Icon className={'forum-icon-fill'} />
        </span>
      );
    }
    const titleText = (
      <h3
        className={classNames(styles.title, titleFontClassName)}
        data-hook="category-list-item__title"
        id={`cl-${this.props.category._id}`}
      >
        {category.label} {categoryBadge}
      </h3>
    );
    return (
      <div className={styles.contentBlock}>
        {this.linkToPosts(titleText, 'category-list-item__link')}
        <span className={styles.description} data-hook="category-list-item__description">
          {category.description}
        </span>
      </div>
    );
  };

  handlePostFetch = sort => () => {
    const { category, fetchCategoryPosts, resetPostTypeFilter, setPostsPageSorting } = this.props;
    sort && setPostsPageSorting(sort, { dontReportBI: true });
    resetPostTypeFilter();
    fetchCategoryPosts({ categoryId: category._id, page: 1, sort });
  };

  linkToPosts = (children, dataHook, sort) => {
    const { category } = this.props;
    return (
      <Link
        className={classNames('forum-text-color', 'forum-link-hover-color')}
        to={`/${category.slug}`}
        onMouseDown={this.handlePostFetch(sort)}
        data-hook={dataHook}
      >
        {children}
      </Link>
    );
  };

  render() {
    const {
      category,
      contentFontClassName,
      isViewsCountEnabled,
      showViewCount,
      language,
      t,
      can,
    } = this.props;

    const shouldShowMoreButton = can('edit', 'category', category);
    const compactNumberLocal = compactNumber(language);

    const moreButton = (
      <MoreButton size={MoreButton.SIZE_SMALL} data-hook="category-list-item__category-actions">
        <CategoryActions hideFollowButton category={category} />
      </MoreButton>
    );
    const followButton = (
      <FollowButton
        category={category}
        className={shouldShowMoreButton ? styles.followButton : ''}
        data-hook="category-list-item__follow-button"
      >
        <FollowNotification isFollowing={category.isSubscribed} />
      </FollowButton>
    );
    const categoryActions = (
      <div className={styles.actionsContainer}>
        {followButton}
        {shouldShowMoreButton && moreButton}
      </div>
    );
    const categoryContent = (
      <div className={classNames(styles.main, { [styles.hasImage]: this.hasImage() })}>
        <div className={classNames(styles.contentPreview, 'forum-text-color')}>
          {this.renderImageIfNeeded()}
          {this.renderContentBlock()}
        </div>
        <div className={styles.mainStats}>
          <div className={classNames(styles.numbers)}>
            {isViewsCountEnabled && (
              <div
                className={styles.numberElementWithIcon}
                data-hook="category-list-item__total-views"
              >
                <ViewCountIconSmall
                  title={t('subcategory-list-item.views')}
                  className="forum-icon-fill"
                />
                <span className={classNames(styles.numberElementText)}>
                  {showViewCount ? compactNumberLocal(category.totalViews) : <span>&nbsp;</span>}
                </span>
              </div>
            )}

            <div
              className={styles.numberElementWithIcon}
              data-hook="category-list-item__total-posts"
            >
              <CommentIconSmall
                title={t('subcategory-list-item.posts', 'subcategories-list-item__total-posts')}
                className="forum-icon-fill"
              />
              <span className={classNames(styles.numberElementText)}>
                {compactNumberLocal(category.totalPosts)}
              </span>
            </div>
          </div>
          <div className={styles.recentActivity}>
            {this.linkToPosts(
              <NewContentIndicator count={category.unseenPostsCount} />,
              null,
              SORT_BY_NEWEST,
            )}
          </div>
        </div>
      </div>
    );

    return (
      <div
        className={classNames(styles.container, contentFontClassName)}
        data-hook="category-list-item"
      >
        {categoryContent}
        {categoryActions}
      </div>
    );
  }
}

CategoryListItemDesktop.propTypes = {
  category: PropTypes.object.isRequired,
  titleFontClassName: PropTypes.string,
  contentFontClassName: PropTypes.string,
  isViewsCountEnabled: PropTypes.bool,
  fetchCategoryPosts: PropTypes.func,
  resetPostTypeFilter: PropTypes.func,
  setPostsPageSorting: PropTypes.func,
  can: PropTypes.func,
  t: PropTypes.func,
  showViewCount: PropTypes.bool,
  hasCategoryPP: PropTypes.bool,
};

const mapRuntimeToProps = (state, { category }, actions) => ({
  fetchCategoryPosts: actions.fetchCategoryPosts,
  resetPostTypeFilter: actions.resetPostTypeFilter,
  setPostsPageSorting: actions.setPostsPageSorting,
  showViewCount: !isCacheableRendering(state),
  hasCategoryPP: hasCategoryPaidPlans(state, category.groups),
  language: getLanguage(state),
});

export default flowRight(
  connect(mapRuntimeToProps),
  withTranslate,
  withFontClassName,
  withPermissions,
)(CategoryListItemDesktop);
