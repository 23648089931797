import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { ArrowDown } from '../icons/arrow-down';
import styles from './dropdown-button.scss';
import MoreButton from '../more-button';
import withTranslate from '@wix/communities-forum-client-commons/dist/src/hoc/with-translate';

export class DropdownButton extends Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
  }

  state = {
    isOpen: false,
  };

  showComponent = () => {
    this.setState({ isOpen: true });
  };

  hideComponent = () => {
    this.setState({ isOpen: false });
  };

  getParentRef = () => this.containerRef;
  render = () => {
    const { className, children, label, isSmall = true, dataHook, t } = this.props;

    const openButton = (
      <div className={classNames(styles.openButton, 'button-primary-text-color')}>
        <span>{t(label)}</span>&nbsp;
        <span
          className={classNames('button-primary-icon-fill', styles.arrow, {
            [styles.arrowUp]: this.state.isOpen,
          })}
        >
          <ArrowDown />
        </span>
      </div>
    );
    return (
      <div data-hook={dataHook} ref={this.containerRef}>
        <MoreButton
          ariaLabel={t(label)}
          icon={openButton}
          className={classNames(styles.moreButtonContainer, {
            [styles.small]: isSmall,
          })}
          buttonClassName={classNames(styles.moreButton,'button-background-color')}
          actionsContainerClassName={classNames(styles.dropdown, className)}
          onHide={this.hideComponent}
          onShow={this.showComponent}
          parentRef={this.getParentRef}
          size={MoreButton.SIZE_EXTENDED}
        >
          {children}
        </MoreButton>
      </div>
    );
  };
}

DropdownButton.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  t: PropTypes.func.isRequired,
  isSmall: PropTypes.bool,
};

DropdownButton.defaultProps = {
  dataHook: 'dropdown-select',
};

export default flowRight(withTranslate)(DropdownButton);
