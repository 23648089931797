import { flowRight } from 'lodash';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { COVER_TYPE_IMAGE } from '../../constants/cover-types';
import MoreButton from '../../components/more-button';
import CategoryActions from '../category-actions';
import Link from '../../components/link/internal-link';
import { isCacheableRendering } from '../../../common/store/basic-params/basic-params-selectors';
import withFontClassName from '../../hoc/with-font-class-name';
import withTranslate from '@wix/communities-forum-client-commons/dist/src/hoc/with-translate';
import styles from './subcategory-list-item-mobile.scss';
import { ImgBackground } from '@wix/communities-image-lib';
import { doesUserHavePermissionsToSeeCategoryActions } from '../category-actions/category-actions';
import withPermissions from '../../hoc/with-permissions';
import { isPrivate } from '@wix/communities-forum-client-commons/dist/src/services/category-privacy-utils';
import { CurrencySignIcon } from '../../components/icons/currency-sign-icon';
import { LockEmptyIcon } from '../../components/icons/lock-empty-icon';
import { hasCategoryPaidPlans } from '../../selectors/paid-plans-selectors';

class SubcategoryListItemMobile extends Component {
  hasImage = () => this.props.category.coverType === COVER_TYPE_IMAGE;

  renderImageIfNeeded = () => {
    return this.hasImage() ? (
      <ImgBackground
        className={styles.image}
        src={this.props.category.cover}
        width={200}
        height={120}
      />
    ) : null;
  };

  renderContentBlock = () => {
    const {
      titleFontClassName,
      category,
      isViewsCountEnabled,
      showViewCount,
      hasCategoryPP,
      t,
    } = this.props;
    let categoryBadge = null;
    if (isPrivate(category)) {
      const Icon = hasCategoryPP ? CurrencySignIcon : LockEmptyIcon;
      categoryBadge = (
        <span className={styles.categoryBadgeWrapper}>
          <Icon className="forum-icon-fill" />
        </span>
      );
    }
    return (
      <div className={styles.contentBlock}>
        <h3
          className={classNames(styles.title, titleFontClassName)}
          data-hook="subcategories-list-item__title"
        >
          <Link to={`/${category.slug}`} style={{ color: 'inherit' }}>
            {category.label}
          </Link>{' '}
          {categoryBadge}
        </h3>
        <span>
          {isViewsCountEnabled &&
            `${showViewCount ? category.totalViews : ''} ${t(
              'subcategory-list-item.views',
            ).toLowerCase()}`}
          {isViewsCountEnabled && (
            <span
              className={classNames(styles.numbersSeparator, 'forum-separator-background-color')}
            />
          )}
          {`${category.totalPosts} ${t('subcategory-list-item.posts').toLowerCase()}`}
        </span>
      </div>
    );
  };

  render() {
    const { category, contentFontClassName, can } = this.props;
    return (
      <div className={classNames(styles.container, contentFontClassName)}>
        <Link
          to={`/${category.slug}`}
          renderInSeo={false}
          className={styles.link}
          data-hook="subcategories-list-item__link"
        >
          <div className={styles.contentPreview}>
            {this.renderImageIfNeeded()}
            {this.renderContentBlock()}
          </div>
        </Link>
        {doesUserHavePermissionsToSeeCategoryActions(can, category) && (
          <MoreButton size={MoreButton.SIZE_SMALL}>
            <CategoryActions category={category} />
          </MoreButton>
        )}
      </div>
    );
  }
}

SubcategoryListItemMobile.propTypes = {
  t: PropTypes.func,
  category: PropTypes.object,
  titleFontClassName: PropTypes.string,
  contentFontClassName: PropTypes.string,
  isViewsCountEnabled: PropTypes.bool,
  showViewCount: PropTypes.bool,
  hasCategoryPP: PropTypes.bool,
};

const mapRuntimeToProps = (state, { category }) => ({
  showViewCount: !isCacheableRendering(state),
  hasCategoryPP: hasCategoryPaidPlans(state, category.groups),
});

export default flowRight(
  connect(mapRuntimeToProps),
  withTranslate,
  withFontClassName,
  withPermissions,
)(SubcategoryListItemMobile);
